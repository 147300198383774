@import "../../assets/styles/utils";
.page{
    width:100%;
    height:100%;
    overflow:hidden;
    position: relative;
    left:0;
    top:0;
    box-sizing: border-box;
}
@media screen and (max-width: 1024px) {
    .fp-section {
        height: auto!important;
    }
}

.banner {
    @include res(margin-top,0,(md:60px));
    width: 100%;
    @include res(height,100%,(md:500px,xs:400px));
    position: relative;
    #banner-swiper {
        width: 100%;
        height: 100%;
        position: relative;
        overflow-x: hidden;
        overflow-y: auto;
        .swiper-slide {
            overflow: hidden;
            a {
                height: 100%;
                position: relative;
                display: block;
                width: 100%;
                img {
                    max-width: 100%;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .banner-title {
                    position: absolute;
                    @include res(top,3.44rem,(md:80px));
                    @include res(left,1.43rem,(md:20px));
                    z-index: 1;
                    color: #ffffff;
                    h1 {
                        font-family: PangMenZhengDao;
                        @include res(font-size,90px,(4 / 9));
                        letter-spacing: 1px;
                        @include res(opacity,0,(md:1));
                    }
                    h2 {
                        font-family: PangMenZhengDao;
                        @include res(font-size,50px,(3 / 5));
                        @include res(letter-spacing,3px,(1 / 3));
                        @include res(margin-bottom,20px,(1 / 2));
                        @include res(opacity,0,(md:1));
                        &.shadow {
                            text-shadow: 1px 1px 1px #000;
                        }
                    }
                    p {
                        font-family: OPPOSans-L;
                        @include res(font-size,26px,(18 / 26));
                        @include res(line-height,43px,(30 / 43));
                        @include res(opacity,0,(md:1));
                    }
                    .more {
                        @include res(margin-top,0.6rem,(xs:20px));
                        @include res(opacity,0,(md:1));
                        img {
                            max-width: fit-content;
                        }
                    }
                }
            }
            &.swiper-slide-active {
                a {
                    .banner-title {
                        h1 {
                            animation-name: title-left;
                            animation-duration: .2s;
                            animation-delay: 1.5s;
                            animation-iteration-count:1;
                            animation-fill-mode:forwards;
                        }
                        h2 {
                            animation-name: title-left;
                            animation-duration: .2s;
                            animation-delay: 1.7s;
                            animation-iteration-count:1;
                            animation-fill-mode:forwards;
                        }
                        p {
                            animation-name: title-left;
                            animation-duration: .2s;
                            animation-delay: 1.9s;
                            animation-iteration-count:1;
                            animation-fill-mode:forwards;
                        }
                        .more {
                            animation-name: title-left;
                            animation-duration: .2s;
                            animation-delay: 2.1s;
                            animation-iteration-count:1;
                            animation-fill-mode:forwards;
                        }
                    }
                }
            }
        }
        .swiper-control {
            @include res(bottom,0.73rem,(xs:40px));
            @include res(left,1.4rem,(xs:40px));
            position: absolute;
            width: max-content;
            .swiper-page {
                width: max-content;
                position: relative;
                bottom: 0;
                left: 0;
                right: auto;
                display: flex;
                align-items: center;
                z-index: 1;
                .swiper-pagination-bullet {
                    width: 9px;
                    height: 9px;
                    background-color: #ffffff;
                    opacity: 1;
                    margin: 0;
                    @include res(margin-right,40px);
                    position: relative;
                    &:last-child {
                        margin-right: 0;
                    }
                    div {
                        position: absolute;
                        width: 55px;
                        height: 55px;
                        border-radius: 50%;
                        border: 1px solid #fff;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%,-50%);
                        display: none;
                    }
                }
                .swiper-pagination-bullet-active {
                    width: 12px;
                    height: 12px;
                    div {
                        display: block;
                    }
                }
            }
            .line {
                width: 100%;
                border-bottom: 1px dashed #fff;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;
            }
        }
    }
    .mouse {
        position: absolute;
        @include res(bottom,0.74rem,(xs:20px));
        @include res(right,113px,(lg:90px,mmd:40px,md:20px));
        z-index: 1;
        @include res(display,block,(xs:none));
        img {
            max-width: fit-content;
        }
        p {
            font-family: OPPOSans-R;
            @include res(font-size,18px,(16 / 18));
            color: #ffffff;
            writing-mode:tb;
            line-height: 25px;
            @include res(margin-bottom,24px,(15 / 24));
            @include res(letter-spacing,5px,(2 / 5));
        }
    }
}
.index-title {
    font-family: PangMenZhengDao;
    @include res(font-size,40px,(24 / 40));
    @include res(letter-spacing,4px,(1 / 2));
    color: #014099;
    width: fit-content;
    @include res(transform,translateY(-200px),(md:translateY(0)));
    transition: all .5s;
    transition-delay:.5s;
    @include res(opacity,0,(md:1));
}
.active {
    .page {
        .content {
            .index-title {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
    .page2_bg {
        .content {
            ul {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
    .page3_bg {
        .content {
            .swiper {
                transform: translateY(0);
                opacity: 1;
            }
            .control {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
    .page4_bg {
        opacity: 1;
        .content {
            .text {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }
    .page5_bg {
        .content {
            .top {
                transform: translateY(0);
                opacity: 1;
            }
            .news-content {
                .news {
                    .left {
                        transform: translateX(0);
                        opacity: 1;
                    }
                    ul {
                        transform: translateX(0);
                        opacity: 1;
                    }
                }
            }
        }
    }
}
.page2_bg {
    @include res(padding-top,10.65vh,(md:30px));
    background: url(./images/index-industry-bg.png)no-repeat;
    @include res(background-position-y,10.65vh,(md:30px));
    .content {
        @include res(padding-left,135px,(lg:10%,md:10px));
        @include res(padding-right,135px,(lg:10%,md:10px));
        @include res(margin-top,60px,(1 / 2));
        ul {
            width: 100%;
            @include res(background,url(./images/index-industry.png)no-repeat,(xs:none));
            background-size: cover;
            @include res(height,63.66vh,(xs:auto));
            display: flex;
            align-items: stretch;
            flex-wrap: wrap;
            justify-content: space-between;
            @include res(margin-top,50px,(lg:5.5vh,xs:30px));
            @include res(transform,translateY(200px),(md:translateY(0)));
            transition: all .5s;
            transition-delay:.5s;
            @include res(opacity,0,(md:1));
            li {
                @include res(width,calc(100% / 5),(xs:49%));
                height: 100%;
                position: relative;
                overflow: hidden;
                @include res(margin-bottom,0,(xs:10px));
                .model {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    img {
                        width: 100%;
                        max-width: 100%;
                        height: 100%;
                        object-fit: cover;
                        transform: scale(1.1);
                    }
                }
                .pic {
                    @include res(position,absolute,(xs:relative));
                    @include res(top,-100%,(xs:0));
                    left: 0;
                    transition: all .5s;
                    width: 100%;
                    @include res(height,72.5%);
                    img {
                        width: 100%;
                        max-width: 100%;
                        height: 100%;
                        object-fit: cover;
                        @include res(aspect-ratio,none,(xs:46 / 71));
                    }
                }
                .bottom {
                    @include res(position,absolute,(xs:relative));
                    z-index: 2;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    box-sizing: border-box;
                    @include res(height,27.5%);
                    @include res(padding-top,40px,(lg:20px,xs:10px));
                    @include res(padding-left,40px,(lg:20px,xs:10px));
                    @include res(padding-right,40px,(lg:20px,xs:10px));
                    @include res(padding-bottom,47px,(lg:25px,xs:15px));
                    transition: all .3s;
                    @include res(background,none,(xs:linear-gradient(180deg, #1e3c72 0%, #2a5298 100%)));
                    .number {
                        font-family: SourceHanSansCN-Regular;
                        @include res(font-size,20px,(4 / 5));
                        @include res(letter-spacing,2px,(1 / 2));
                        color: #ffffff;
                        @include res(margin-bottom,25px,(lg:15px));
                    }
                    .text {
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                        h2 {
                            font-family: PangMenZhengDao;
                            @include res(font-size,31px,(lg:28px,mmd:24px,xs:20px));
                            color: #eeeeee;
                            @include res(letter-spacing,3px,(1 / 3));
                        }
                    }
                }
                .color-block {
                    background-image: linear-gradient(180deg, #1e3c72 0%, #2a5298 100%);
                    position: absolute;
                    width: 100%;
                    @include res(height,27.5%);
                    left: 0;
                    bottom: -100%;
                    z-index: 1;
                    transition: all .5s;
                    @include res(display,block,(xs:none));
                }
                &:nth-child(2n) {
                    .model {
                        background: rgba(255,255,255,.1);
                    }
                }
                &:hover {
                    .pic {
                        top: 0;
                    }
                    .color-block {
                        bottom: 0;
                    }
                }
            }
        }
    }
}
.page3_bg {
    @include res(padding-top,10.65vh,(xs:60px));
    background: url(./images/index-solution-bg.png)no-repeat;
    background-size: cover;
    .content {
        @include res(width,85.9%,(xs:98%));
        margin: auto;
        @include res(margin-top,6.52vh,(xs:30px));
        box-sizing: border-box;  
        .index-title {
            h2 {
                color: #fff;
            }
        }
        ul {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-wrap: wrap;
            @include res(margin-top,30px,(2 / 3));
            li {
                @include res(width,394px,(lg:24%,xs:49.5%));
                aspect-ratio: 394 / 289;
                background: rgba(255,255,255,.7);
                transition: all .3s;
                @include res(margin-bottom,20px,(1 / 2));
                box-sizing: border-box;
                @include res(padding-left,20px,(1 / 2));
                @include res(padding-right,20px,(1 / 2));
                .pic {
                    @include res(width,96px,(60 / 96));
                    @include res(height,96px,(60 / 96));
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    border: solid 1px #595959;
                    transition: all .3s;
                    position: relative;
                    margin: auto;
                    @include res(margin-top,4.7vh,(xs:20px));
                    img {
                        position: absolute;
                        transition: all .3s;
                        max-width: fit-content;
                        @include res(width,100%,(3 / 5));
                        &.img1 {
                            opacity: 1;
                        }
                        &.img2 {
                            opacity: 0;
                        }
                    }
                }
                h2 {
                    text-align: center;
                    font-family: SourceHanSansCN-Regular;
                    @include res(font-size,24px,(4 / 5));
                    letter-spacing: 1px;
                    color: #595959;
                    transition: all .3s;
                    @include res(margin-top,3.45vh,(xs:15px));
                    @include res(margin-bottom,2.7vh,(xs:10px));
                }
                .more {
                    opacity: 0;
                    transition: all .3s;
                    img {
                        margin: auto;
                    }
                }
                &:hover {
                    background: #fff;
                    .pic {
                        border-color: #022A9D;
                        img {
                            &.img1 {
                                opacity: 0;
                            }
                            &.img2 {
                                opacity: 1;
                            }
                        }
                    }
                    h2 {
                        color: #022A9D;
                    }
                    .more {
                        opacity: 1;
                    }
                }
            }
            .placeholder {
                @include res(width,394px,(lg:24%,xs:49.5%));
                aspect-ratio: 394 / 289;
                transition: all .3s;
                @include res(margin-bottom,20px,(1 / 2));
                box-sizing: border-box;
                @include res(padding-left,20px,(1 / 2));
                @include res(padding-right,20px,(1 / 2));
            }
        }
    }
}
.page4_bg {
    background: url(./images/index-video.jpg)no-repeat;
    background-size: cover;
    @include res(opacity,0,(md:1));
    transition: all .5s;
    transition-delay:.5s;
    position: relative;
    @include res(margin-top,0,(md:30px));
    .content {
        @include res(width,1560px,(lg:90%,xs:98%));
        margin: auto;
        @include res(padding-top,16.3vh,(md:30px));
        @include res(margin-top,10.65vh,(md:0));
        @include res(padding-bottom,0,(md:30px));
        .text {
            color: #fff;
            text-align: center;
            @include res(transform,translateY(-200px),(md:translateY(0)));
            transition: all .5s;
            transition-delay:.5s;
            @include res(opacity,0,(md:1));
            h2 {
                font-family: PangMenZhengDao;
                @include res(font-size,40px,(24 / 40));
                @include res(letter-spacing,4px,(1 / 2));
                @include res(margin-bottom,3.8vh,(xs:20px));
            }
            p {
                font-family: SourceHanSansCN-Light;
                @include res(font-size,20px,(4 / 5));
                letter-spacing: 1px;
                @include res(line-height,35px,(6 / 7));
            }
        }
        .play {
            cursor: pointer;
            width: fit-content;
            margin: auto;
            @include res(margin-top,8.7vh,(xs:30px));
            @include res(display,block,(xs:none));
        }
        ul {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            text-align: center;
            @include res(flex-wrap,nowrap,(xs:wrap));
            @include res(margin-top,13.47vh,(xs:30px));
            li {
                color: #fff;
                @include res(width,fit-content,(xs:49%));
                @include res(margin-bottom,0,(xs:10px));
                .mun_top{
                    display: flex;
                    align-items: center;
                    font-family: Impact;
                    justify-content: center;
                    @include res(font-size,68px,(40 / 68));
                }
                p {
                    font-family: SourceHanSansCN-Light;
                    @include res(font-size,20px,(4 / 5));
                    @include res(letter-spacing,2px,(1 / 2));
                }
            }
            .line {
                width: 1px;
                background: #fff;
                height: auto;
                opacity: .55;
            }
        }
    }
    .video {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999999;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0,0,0,.7);
        width: 100%;
        height: 100%;
        display: none;
        video {
            display: block;
            @include res(width,100vh,(md:90%));
            @include res(height,56.2vh,(xs:auto));
            object-fit: cover;
        }
        .out {
            position: fixed;
            @include res(top,5vh,(xs:10px));
            @include res(right,5vh,(xs:10px));
            cursor: pointer;
        }
    }
    .video-phone {
        @include res(display,none,(xs:block));
        width: 100%;
        video {
            object-fit: cover;
            width: 100%;
            height: 30vh;
        }
    }
}
.page5_bg {
    @include res(padding-top,10.65vh,(xs:60px));
    background: url(./images/index-news-bg.jpg)no-repeat;
    @include res(background-position-y,10.65vh,(xs:60px));
    @include res(padding-bottom,0,(md:30px));
    .content {
        @include res(padding-left,135px,(lg:7%,xs:10px));
        @include res(padding-right,135px,(lg:7%,xs:10px));
        @include res(margin-top,60px,(1 / 2));
        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            @include res(transform,translateY(-200px),(md:translateY(0)));
            transition: all .5s;
            transition-delay:.5s;
            @include res(opacity,0,(md:1));
            position: relative;
            z-index: 10;
            .line {
                width: 100%;
                height: 1px;
                background-color: #707070;
                @include res(margin-left,50px,(1 / 2));
                @include res(margin-right,35px,(3 / 7));
                flex: 1;
            }
            ul {
                display: flex;
                align-items: center;
                li {
                    font-family: OPPOSans-L;
                    @include res(font-size,24px,(20 / 24));
                    letter-spacing: 2px;
                    color: #212121;
                    display: flex;
                    align-items: stretch;
                    a {
                        transition: all .3s;
                    }
                    span {
                        display: block;
                        width: 1px;
                        height: auto;
                        background-color: #707070;
                        @include res(margin-left,35px,(3 / 7));
                        @include res(margin-right,35px,(3 / 7));
                    }
                    &:last-child {
                        span {
                            display: none;
                        }
                    }
                    &:hover,&.active {
                        color: #014099;
                        font-family: OPPOSans-M;
                    }
                }
            }
        }
        .news-content {
            .news {
                display: none;
                align-items: stretch;
                justify-content: space-between;
                flex-wrap: wrap;
                @include res(margin-top,11.42vh,(xs:30px));
                &.active {
                    display: flex;
                }
                .left {
                    @include res(width,479px,(lg:30%,xs:100%));
                    box-sizing: border-box;
                    @include res(padding-top,72px,(lg:7vh,xs:20px));
                    @include res(padding-left,62px,(lg:40px,mmd:25px,xs:15px));
                    @include res(padding-right,48px,(lg:35px,mmd:25px,xs:15px));
                    @include res(padding-bottom,0,(xs:20px));
                    background-color: #022a9d;
                    position: relative;
                    @include res(transform,translateX(-200px),(md:translateY(0)));
                    transition: all .5s;
                    transition-delay:.5s;
                    @include res(opacity,0,(md:1));
                    .bg {
                        img {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            z-index: 0;
                        }
                    }
                    .text {
                        position: relative;
                        z-index: 1;
                        color: #fff;
                        .time {
                            display: flex;
                            align-items: center;
                            @include res(margin-bottom,5.43vh,(xs:20px));
                            span {
                                color: #fff;
                                font-size: 24px;
                            }
                            h2 {
                                font-family: OPPOSans-R;
                                @include res(font-size,24px,(4  / 5));
                                @include res(margin-left,25px,(3 / 5));
                            }
                        }
                        p {
                            font-family: OPPOSans-R;
                            @include res(font-size,16px,(14 / 16));
                            line-height: 28px;
                            @include res(margin-bottom,3.26vh,(xs:15px));
                            text-align: justify;
                        }
                        .more {
                            width: 128px;
                            height: 28px;
                            border-radius: 20px;
                            background: #fff;
                            display: flex;
                            align-items: center;
                            padding-left: 5px;
                            font-size: 16px;
                            color: #022A9D;
                            font-family: OPPOSans-R;
                            .scroll-cont {
                                overflow: hidden;
                                display: flex;
                                flex-direction: column;
                                height: 24px;
                                div {
                                    display: flex;
                                    align-items: center;
                                    transition: all .3s;
                                    span {
                                        display: flex;
                                        width: 24px;
                                        height: 24px;
                                        border-radius: 50%;
                                        background: #022A9D;
                                        align-items: center;
                                        justify-content: center;
                                        color: #fff;
                                        margin-right: 15px;
                                    }
                                }
                            }
                        }
                    }
                    &:hover {
                        .text {
                            .more {
                                .scroll-cont {
                                    div {
                                        transform: translateY(-100%);
                                        img {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                ul {
                    @include res(width,1015px,(lg:65%,xs:100%));
                    @include res(transform,translateX(200px),(md:translateY(0)));
                    transition: all .5s;
                    transition-delay:.5s;
                    @include res(opacity,0,(md:1));
                    @include res(margin-top,0,(xs:20px));
                    li {
                        margin-bottom: 16px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        a {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            @include res(flex-wrap,nowrap,(xs:wrap));
                            .pic {
                                @include res(width,457px,(lg:45%,xs:100%));
                                @include res(margin-bottom,0,(xs:20px));
                                aspect-ratio: 457 / 219;
                                overflow: hidden;
                                img {
                                    width: 100%;
                                    max-width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                    transition: all .3s;
                                }
                            }
                            .text {
                                @include res(width,503px,(lg:50%,xs:100%));
                                .title {
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    .time {
                                        display: flex;
                                        align-items: center;
                                        span {
                                            color: #7f7f7f;
                                            font-size: 24px;
                                        }
                                        h2 {
                                            font-family: OPPOSans-R;
                                            @include res(font-size,20px,(4 / 5));
                                            @include res(margin-left,12px,(10 / 12));
                                            color: #7f7f7f;
                                        }
                                    }
                                    .more {
                                        width: 115px;
                                        height: 26px;
                                        border-radius: 20px;
                                        background: #022A9D;
                                        padding-left: 5px;
                                        font-size: 14px;
                                        color: #fff;
                                        font-family: OPPOSans-R;
                                        display: flex;
                                        align-items: center;
                                        .scroll-cont {
                                            overflow: hidden;
                                            display: flex;
                                            flex-direction: column;
                                            height: 20px;
                                            div {
                                                display: flex;
                                                align-items: center;
                                                transition: all .3s;
                                                span {
                                                    display: flex;
                                                    width: 20px;
                                                    height: 20px;
                                                    border-radius: 50%;
                                                    background: #fff;
                                                    align-items: center;
                                                    justify-content: center;
                                                    color: #022A9D;
                                                    margin-right: 10px;
                                                }
                                            }
                                        }
                                    }
                                }
                                p {
                                    font-family: OPPOSans-R;
                                    @include res(font-size,16px,(14 / 16));
                                    line-height: 28px;
                                    @include res(margin-top,28px,(10 / 28));
                                    @include res(margin-bottom,3.26vh,(xs:15px));
                                    text-align: justify;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 3;
                                }
                            }
                        }
                        &:hover {
                            a {
                                .pic {
                                    img {
                                        transform: scale(1.1);
                                    }
                                }
                                .text {
                                    .more {
                                        .scroll-cont {
                                            div {
                                                transform: translateY(-100%);
                                                img {
                                                    opacity: 1;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
@keyframes title-left {
    from {opacity: 0;transform: translateX(-100px);}
    to {opacity: 1;transform: translateX(0);}
}