.hamburger {
  display: block;
  cursor: pointer;
  background-color: transparent;
  overflow: visible;
  position: relative;
  width: 28px;
  height: 19px;
}

.hamburger > div, .hamburger > view {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

.hamburger > div, .hamburger > div::before, .hamburger > div::after, .hamburger > view, .hamburger > view::before, .hamburger > view::after {
  width: 28px;
  height: 3px;
  background-color: #afafaf;
  border-radius: 0;
  position: absolute;
  transition-property: all;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger > div::before, .hamburger > div::after, .hamburger > view::before, .hamburger > view::after {
  content: "";
  display: block;
}

.hamburger > div::before, .hamburger > view::before {
  top: -8px;
}

.hamburger > div::after, .hamburger > view::after {
  bottom: -8px;
}

.hamburger.hamburger-1 > div, .hamburger.hamburger-1 > view {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-1 > div::after, .hamburger.hamburger-1 > view::after {
  top: -16px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear, background-color 0.3s linear;
}

.hamburger.hamburger-1 > div::before, .hamburger.hamburger-1 > view::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-1.active > div, .hamburger.hamburger-1.active view {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-1.active > div::after, .hamburger.hamburger-1.active view::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear, background-color 0.3s linear;
}

.hamburger.hamburger-1.active > div::before, .hamburger.hamburger-1.active view::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-2 > div, .hamburger.hamburger-2 > view {
  top: auto;
  bottom: 0;
  transition-duration: 0.15s;
  transition-delay: 0.15s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-2 > div::after, .hamburger.hamburger-2 > view::after {
  top: -16px;
  transition: top 0.3s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear, background-color 0.3s linear;
}

.hamburger.hamburger-2 > div::before, .hamburger.hamburger-2 > view::before {
  transition: top 0.12s 0.3s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-2.active > div, .hamburger.hamburger-2.active > view {
  transform: translate3d(0, -8px, 0) rotate(45deg);
  transition-delay: 0.32s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-2.active > div::after, .hamburger.hamburger-2.active > view::after {
  top: 0;
  opacity: 0;
  transition: top 0.3s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.27s linear, background-color 0.3s linear;
}

.hamburger.hamburger-2.active > div::before, .hamburger.hamburger-2.active > view::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.12s 0.18s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.15s 0.42s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-3 > div, .hamburger.hamburger-3 > view {
  top: 0;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-top: 0;
}

.hamburger.hamburger-3 > div::before, .hamburger.hamburger-3 > view::before {
  top: 8px;
  transition: opacity 0.15s 0.4s ease, background-color 0.3s linear;
}

.hamburger.hamburger-3 > div::after, .hamburger.hamburger-3 > view::after {
  top: 16px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), background-color 0.3s linear;
}

.hamburger.hamburger-3.active > div, .hamburger.hamburger-3.active > view {
  transform: translate3d(0, 8px, 0) rotate(135deg);
  transition-delay: .1s;
}

.hamburger.hamburger-3.active > div::before, .hamburger.hamburger-3.active > view::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger.hamburger-3.active > div:after, .hamburger.hamburger-3.active > view:after {
  transform: translate3d(0, -16px, 0) rotate(-270deg);
  transition-delay: .1s;
}

.hamburger.hamburger-4 > div, .hamburger.hamburger-4 > view {
  top: 0;
  transition-duration: .4s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  margin-top: 0;
}

.hamburger.hamburger-4 > div::before, .hamburger.hamburger-4 > view::before {
  top: 8px;
  transition: opacity 0.15s 0.4s ease, background-color 0.3s linear;
}

.hamburger.hamburger-4 > div::after, .hamburger.hamburger-4 > view::after {
  top: 16px;
  transition: transform 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55), background-color 0.3s linear;
}

.hamburger.hamburger-4.active > div, .hamburger.hamburger-4.active > view {
  transform: translate3d(0, 8px, 0) rotate(-135deg);
  transition-delay: .1s;
}

.hamburger.hamburger-4.active > div::before, .hamburger.hamburger-4.active > view::before {
  transition-delay: 0s;
  opacity: 0;
}

.hamburger.hamburger-4.active > div:after, .hamburger.hamburger-4.active > view:after {
  transform: translate3d(0, -16px, 0) rotate(270deg);
  transition-delay: .1s;
}

.hamburger.hamburger-5 > div, .hamburger.hamburger-5 > view {
  margin-top: -2px;
  transition: background-color 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5 > div::before, .hamburger.hamburger-5 > view::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5 > div::after, .hamburger.hamburger-5 > view::after {
  top: 8px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-5.active > div, .hamburger.hamburger-5.active > view {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger.hamburger-5.active > div::before, .hamburger.hamburger-5.active > view::before {
  left: -56px;
  top: -56px;
  transform: translate3d(56px, 56px, 0) rotate(45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-5.active > div::after, .hamburger.hamburger-5.active > view::after {
  right: -56px;
  top: -56px;
  transform: translate3d(-56px, 56px, 0) rotate(-45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-6 > div, .hamburger.hamburger-6 > view {
  margin-top: -2px;
  transition: background-color 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6 > div::before, .hamburger.hamburger-6 > view::before {
  left: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, left 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6 > div::after, .hamburger.hamburger-6 > view::after {
  top: 8px;
  right: 0;
  transition: transform 0.2s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.2s linear, right 0.2s 0.25s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-6.active > div, .hamburger.hamburger-6.active > view {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent;
}

.hamburger.hamburger-6.active > div::before, .hamburger.hamburger-6.active > view::before {
  left: -56px;
  top: 56px;
  transform: translate3d(56px, -56px, 0) rotate(-45deg);
  transition: left 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-6.active > div::after, .hamburger.hamburger-6.active > view::after {
  right: -56px;
  top: 56px;
  transform: translate3d(-56px, -56px, 0) rotate(45deg);
  transition: right 0.2s ease-out, top 0.05s 0.2s linear, transform 0.2s 0.25s cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0.3s linear;
}

.hamburger.hamburger-7 > div, .hamburger.hamburger-7 > view {
  top: 0;
  margin-top: 0;
}

.hamburger.hamburger-7 > div::before, .hamburger.hamburger-7 > view::before {
  top: 8px;
  transition-property: transform, opacity, background-color;
  transition-timing-function: ease;
  transition-duration: .2s;
}

.hamburger.hamburger-7 > div::after, .hamburger.hamburger-7 > view::after {
  top: 16px;
}

.hamburger.hamburger-7.active > div, .hamburger.hamburger-7.active > view {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger.hamburger-7.active > div::before, .hamburger.hamburger-7.active > view::before {
  transform: rotate(-45deg) translate3d(-4px, -5px, 0);
  opacity: 0;
}

.hamburger.hamburger-7.active > div::after, .hamburger.hamburger-7.active > view::after {
  transform: translate3d(0, -16px, 0) rotate(-90deg);
}

.hamburger.hamburger-8 > div, .hamburger.hamburger-8 > view {
  top: 0;
  margin-top: 0;
}

.hamburger.hamburger-8 > div::before, .hamburger.hamburger-8 > view::before {
  top: 8px;
  transition-property: transform, opacity, background-color;
  transition-timing-function: ease;
  transition-duration: .2s;
}

.hamburger.hamburger-8 > div::after, .hamburger.hamburger-8 > view::after {
  top: 16px;
}

.hamburger.hamburger-8.active > div, .hamburger.hamburger-8.active > view {
  transform: translate3d(0, 8px, 0) rotate(-45deg);
}

.hamburger.hamburger-8.active > div::before, .hamburger.hamburger-8.active > view::before {
  transform: rotate(45deg) translate3d(-4px, -5px, 0);
  opacity: 0;
}

.hamburger.hamburger-8.active > div::after, .hamburger.hamburger-8.active > view::after {
  transform: translate3d(0, -16px, 0) rotate(90deg);
}

.hamburger.hamburger-9 > div, .hamburger.hamburger-9 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-9 > div::before, .hamburger.hamburger-9 > view::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-9 > div::after, .hamburger.hamburger-9 > view::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-9.active > div, .hamburger.hamburger-9.active > view {
  transform: rotate(225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-9.active > div::before, .hamburger.hamburger-9.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease-out, opacity 0.1s 0.14s ease-out, background-color 0.3s linear;
}

.hamburger.hamburger-9.active > div::after, .hamburger.hamburger-9.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-10 > div, .hamburger.hamburger-10 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-10 > div::before, .hamburger.hamburger-10 > view::before {
  transition: top 0.1s 0.34s ease-in, opacity 0.1s ease-in, background-color 0.3s linear;
}

.hamburger.hamburger-10 > div::after, .hamburger.hamburger-10 > view::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-10.active > div, .hamburger.hamburger-10.active > view {
  transform: rotate(-225deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-10.active > div::before, .hamburger.hamburger-10.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease-out, opacity 0.1s 0.14s ease-out, background-color 0.3s linear;
}

.hamburger.hamburger-10.active > div::after, .hamburger.hamburger-10.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-11 > div, .hamburger.hamburger-11 > view {
  margin-top: -2px;
  transition-duration: .1s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger.hamburger-11 > div::before, .hamburger.hamburger-11 > view::before {
  transition: top 0.1s 0.14s ease, opacity 0.1s ease, background-color 0.3s linear;
}

.hamburger.hamburger-11 > div::after, .hamburger.hamburger-11 > view::after {
  transition: bottom 0.1s 0.14s ease, transform 0.1s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0.3s linear;
}

.hamburger.hamburger-11.active > div, .hamburger.hamburger-11.active > view {
  transform: rotate(45deg);
  transition-delay: 0.14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.hamburger.hamburger-11.active > div::before, .hamburger.hamburger-11.active > view::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease, opacity 0.1s 0.14s ease, background-color 0.3s linear;
}

.hamburger.hamburger-11.active > div::after, .hamburger.hamburger-11.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease, transform 0.1s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0.3s linear;
}

.hamburger.hamburger-12 > div, .hamburger.hamburger-12 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-12 > div::before, .hamburger.hamburger-12 > div::after, .hamburger.hamburger-12 > view::before, .hamburger.hamburger-12 > view::after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger.hamburger-12 > div::before, .hamburger.hamburger-12 > view::before {
  transition-property: top, opacity, background-color;
}

.hamburger.hamburger-12 > div::after, .hamburger.hamburger-12 > view::after {
  transition-property: bottom, transform, background-color;
}

.hamburger.hamburger-12.active > div, .hamburger.hamburger-12.active > view {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-12.active > div::before, .hamburger.hamburger-12.active > div::after, .hamburger.hamburger-12.active > view::before, .hamburger.hamburger-12.active > view::after {
  transition-delay: 0s;
}

.hamburger.hamburger-12.active > div::before, .hamburger.hamburger-12.active > view::before {
  top: 0;
  opacity: 0;
}

.hamburger.hamburger-12.active > div::after, .hamburger.hamburger-12.active > view::after {
  bottom: 0;
  transform: rotate(90deg);
}

.hamburger.hamburger-13 > div, .hamburger.hamburger-13 > view {
  margin-top: -2px;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-13 > div::before, .hamburger.hamburger-13 > div::after, .hamburger.hamburger-13 > view::before, .hamburger.hamburger-13 > view::after {
  transition-duration: 0s;
  transition-delay: .1s;
  transition-timing-function: linear;
}

.hamburger.hamburger-13 > div::before, .hamburger.hamburger-13 > view::before {
  transition-property: top, opacity;
}

.hamburger.hamburger-13 > div::after, .hamburger.hamburger-13 > view::after {
  transition-property: bottom, transform, background-color;
}

.hamburger.hamburger-13.active > div, .hamburger.hamburger-13.active > view {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.hamburger.hamburger-13.active > div::before, .hamburger.hamburger-13.active > div::after, .hamburger.hamburger-13.active > view::before, .hamburger.hamburger-13.active > view::after {
  transition-delay: 0s;
}

.hamburger.hamburger-13.active > div::before, .hamburger.hamburger-13.active > view::before {
  top: 0;
  opacity: 0;
}

.hamburger.hamburger-13.active > div::after, .hamburger.hamburger-13.active > view::after {
  bottom: 0;
  transform: rotate(-90deg);
}

.page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  left: 0;
  top: 0;
  box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
  .fp-section {
    height: auto !important;
  }
}

.banner {
  margin-top: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

@media (max-width: 1024px) {
  .banner {
    margin-top: 60px;
  }
}

@media (max-width: 1024px) {
  .banner {
    height: 500px;
  }
}

@media (max-width: 767px) {
  .banner {
    height: 400px;
  }
}

.banner #banner-swiper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.banner #banner-swiper .swiper-slide {
  overflow: hidden;
}

.banner #banner-swiper .swiper-slide a {
  height: 100%;
  position: relative;
  display: block;
  width: 100%;
}

.banner #banner-swiper .swiper-slide a img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner #banner-swiper .swiper-slide a .banner-title {
  position: absolute;
  top: 3.44rem;
  left: 1.43rem;
  z-index: 1;
  color: #ffffff;
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-slide a .banner-title {
    top: 80px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-slide a .banner-title {
    left: 20px;
  }
}

.banner #banner-swiper .swiper-slide a .banner-title h1 {
  font-family: PangMenZhengDao;
  font-size: 90px;
  letter-spacing: 1px;
  opacity: 0;
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-slide a .banner-title h1 {
    font-size: 80px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-slide a .banner-title h1 {
    font-size: 70px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-slide a .banner-title h1 {
    font-size: 60px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-slide a .banner-title h1 {
    font-size: 50px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-slide a .banner-title h1 {
    font-size: 40px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-slide a .banner-title h1 {
    opacity: 1;
  }
}

.banner #banner-swiper .swiper-slide a .banner-title h2 {
  font-family: PangMenZhengDao;
  font-size: 50px;
  letter-spacing: 3px;
  margin-bottom: 20px;
  opacity: 0;
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-slide a .banner-title h2 {
    font-size: 46px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-slide a .banner-title h2 {
    font-size: 42px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-slide a .banner-title h2 {
    font-size: 38px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-slide a .banner-title h2 {
    font-size: 34px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-slide a .banner-title h2 {
    font-size: 30px;
  }
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-slide a .banner-title h2 {
    letter-spacing: 2.6px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-slide a .banner-title h2 {
    letter-spacing: 2.2px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-slide a .banner-title h2 {
    letter-spacing: 1.8px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-slide a .banner-title h2 {
    letter-spacing: 1.4px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-slide a .banner-title h2 {
    letter-spacing: 1px;
  }
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-slide a .banner-title h2 {
    margin-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-slide a .banner-title h2 {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-slide a .banner-title h2 {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-slide a .banner-title h2 {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-slide a .banner-title h2 {
    margin-bottom: 10px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-slide a .banner-title h2 {
    opacity: 1;
  }
}

.banner #banner-swiper .swiper-slide a .banner-title h2.shadow {
  text-shadow: 1px 1px 1px #000;
}

.banner #banner-swiper .swiper-slide a .banner-title p {
  font-family: OPPOSans-L;
  font-size: 26px;
  line-height: 43px;
  opacity: 0;
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-slide a .banner-title p {
    font-size: 24.4px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-slide a .banner-title p {
    font-size: 22.8px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-slide a .banner-title p {
    font-size: 21.2px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-slide a .banner-title p {
    font-size: 19.6px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-slide a .banner-title p {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .banner #banner-swiper .swiper-slide a .banner-title p {
    line-height: 40.4px;
  }
}

@media (max-width: 1366px) {
  .banner #banner-swiper .swiper-slide a .banner-title p {
    line-height: 37.8px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-slide a .banner-title p {
    line-height: 35.2px;
  }
}

@media (max-width: 991px) {
  .banner #banner-swiper .swiper-slide a .banner-title p {
    line-height: 32.6px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-slide a .banner-title p {
    line-height: 30px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-slide a .banner-title p {
    opacity: 1;
  }
}

.banner #banner-swiper .swiper-slide a .banner-title .more {
  margin-top: 0.6rem;
  opacity: 0;
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-slide a .banner-title .more {
    margin-top: 20px;
  }
}

@media (max-width: 1024px) {
  .banner #banner-swiper .swiper-slide a .banner-title .more {
    opacity: 1;
  }
}

.banner #banner-swiper .swiper-slide a .banner-title .more img {
  max-width: fit-content;
}

.banner #banner-swiper .swiper-slide.swiper-slide-active a .banner-title h1 {
  animation-name: title-left;
  animation-duration: .2s;
  animation-delay: 1.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.banner #banner-swiper .swiper-slide.swiper-slide-active a .banner-title h2 {
  animation-name: title-left;
  animation-duration: .2s;
  animation-delay: 1.7s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.banner #banner-swiper .swiper-slide.swiper-slide-active a .banner-title p {
  animation-name: title-left;
  animation-duration: .2s;
  animation-delay: 1.9s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.banner #banner-swiper .swiper-slide.swiper-slide-active a .banner-title .more {
  animation-name: title-left;
  animation-duration: .2s;
  animation-delay: 2.1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.banner #banner-swiper .swiper-control {
  bottom: 0.73rem;
  left: 1.4rem;
  position: absolute;
  width: max-content;
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-control {
    bottom: 40px;
  }
}

@media (max-width: 767px) {
  .banner #banner-swiper .swiper-control {
    left: 40px;
  }
}

.banner #banner-swiper .swiper-control .swiper-page {
  width: max-content;
  position: relative;
  bottom: 0;
  left: 0;
  right: auto;
  display: flex;
  align-items: center;
  z-index: 1;
}

.banner #banner-swiper .swiper-control .swiper-page .swiper-pagination-bullet {
  width: 9px;
  height: 9px;
  background-color: #ffffff;
  opacity: 1;
  margin: 0;
  margin-right: 40px;
  position: relative;
}

.banner #banner-swiper .swiper-control .swiper-page .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.banner #banner-swiper .swiper-control .swiper-page .swiper-pagination-bullet div {
  position: absolute;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  border: 1px solid #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

.banner #banner-swiper .swiper-control .swiper-page .swiper-pagination-bullet-active {
  width: 12px;
  height: 12px;
}

.banner #banner-swiper .swiper-control .swiper-page .swiper-pagination-bullet-active div {
  display: block;
}

.banner #banner-swiper .swiper-control .line {
  width: 100%;
  border-bottom: 1px dashed #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.banner .mouse {
  position: absolute;
  bottom: 0.74rem;
  right: 113px;
  z-index: 1;
  display: block;
}

@media (max-width: 767px) {
  .banner .mouse {
    bottom: 20px;
  }
}

@media (max-width: 1600px) {
  .banner .mouse {
    right: 90px;
  }
}

@media (max-width: 1366px) {
  .banner .mouse {
    right: 40px;
  }
}

@media (max-width: 1024px) {
  .banner .mouse {
    right: 20px;
  }
}

@media (max-width: 767px) {
  .banner .mouse {
    display: none;
  }
}

.banner .mouse img {
  max-width: fit-content;
}

.banner .mouse p {
  font-family: OPPOSans-R;
  font-size: 18px;
  color: #ffffff;
  writing-mode: tb;
  line-height: 25px;
  margin-bottom: 24px;
  letter-spacing: 5px;
}

@media (max-width: 1600px) {
  .banner .mouse p {
    font-size: 17.6px;
  }
}

@media (max-width: 1366px) {
  .banner .mouse p {
    font-size: 17.2px;
  }
}

@media (max-width: 1024px) {
  .banner .mouse p {
    font-size: 16.8px;
  }
}

@media (max-width: 991px) {
  .banner .mouse p {
    font-size: 16.4px;
  }
}

@media (max-width: 767px) {
  .banner .mouse p {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .banner .mouse p {
    margin-bottom: 22.2px;
  }
}

@media (max-width: 1366px) {
  .banner .mouse p {
    margin-bottom: 20.4px;
  }
}

@media (max-width: 1024px) {
  .banner .mouse p {
    margin-bottom: 18.6px;
  }
}

@media (max-width: 991px) {
  .banner .mouse p {
    margin-bottom: 16.8px;
  }
}

@media (max-width: 767px) {
  .banner .mouse p {
    margin-bottom: 15px;
  }
}

@media (max-width: 1600px) {
  .banner .mouse p {
    letter-spacing: 4.4px;
  }
}

@media (max-width: 1366px) {
  .banner .mouse p {
    letter-spacing: 3.8px;
  }
}

@media (max-width: 1024px) {
  .banner .mouse p {
    letter-spacing: 3.2px;
  }
}

@media (max-width: 991px) {
  .banner .mouse p {
    letter-spacing: 2.6px;
  }
}

@media (max-width: 767px) {
  .banner .mouse p {
    letter-spacing: 2px;
  }
}

.index-title {
  font-family: PangMenZhengDao;
  font-size: 40px;
  letter-spacing: 4px;
  color: #014099;
  width: fit-content;
  transform: translateY(-200px);
  transition: all .5s;
  transition-delay: .5s;
  opacity: 0;
}

@media (max-width: 1600px) {
  .index-title {
    font-size: 36.8px;
  }
}

@media (max-width: 1366px) {
  .index-title {
    font-size: 33.6px;
  }
}

@media (max-width: 1024px) {
  .index-title {
    font-size: 30.4px;
  }
}

@media (max-width: 991px) {
  .index-title {
    font-size: 27.2px;
  }
}

@media (max-width: 767px) {
  .index-title {
    font-size: 24px;
  }
}

@media (max-width: 1600px) {
  .index-title {
    letter-spacing: 3.6px;
  }
}

@media (max-width: 1366px) {
  .index-title {
    letter-spacing: 3.2px;
  }
}

@media (max-width: 1024px) {
  .index-title {
    letter-spacing: 2.8px;
  }
}

@media (max-width: 991px) {
  .index-title {
    letter-spacing: 2.4px;
  }
}

@media (max-width: 767px) {
  .index-title {
    letter-spacing: 2px;
  }
}

@media (max-width: 1024px) {
  .index-title {
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .index-title {
    opacity: 1;
  }
}

.active .page .content .index-title {
  transform: translateY(0);
  opacity: 1;
}

.active .page2_bg .content ul {
  transform: translateY(0);
  opacity: 1;
}

.active .page3_bg .content .swiper {
  transform: translateY(0);
  opacity: 1;
}

.active .page3_bg .content .control {
  transform: translateY(0);
  opacity: 1;
}

.active .page4_bg {
  opacity: 1;
}

.active .page4_bg .content .text {
  transform: translateY(0);
  opacity: 1;
}

.active .page5_bg .content .top {
  transform: translateY(0);
  opacity: 1;
}

.active .page5_bg .content .news-content .news .left {
  transform: translateX(0);
  opacity: 1;
}

.active .page5_bg .content .news-content .news ul {
  transform: translateX(0);
  opacity: 1;
}

.page2_bg {
  padding-top: 10.65vh;
  background: url(./images/index-industry-bg.png) no-repeat;
  background-position-y: 10.65vh;
}

@media (max-width: 1024px) {
  .page2_bg {
    padding-top: 30px;
  }
}

@media (max-width: 1024px) {
  .page2_bg {
    background-position-y: 30px;
  }
}

.page2_bg .content {
  padding-left: 135px;
  padding-right: 135px;
  margin-top: 60px;
}

@media (max-width: 1600px) {
  .page2_bg .content {
    padding-left: 10%;
  }
}

@media (max-width: 1024px) {
  .page2_bg .content {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .page2_bg .content {
    padding-right: 10%;
  }
}

@media (max-width: 1024px) {
  .page2_bg .content {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .page2_bg .content {
    margin-top: 54px;
  }
}

@media (max-width: 1366px) {
  .page2_bg .content {
    margin-top: 48px;
  }
}

@media (max-width: 1024px) {
  .page2_bg .content {
    margin-top: 42px;
  }
}

@media (max-width: 991px) {
  .page2_bg .content {
    margin-top: 36px;
  }
}

@media (max-width: 767px) {
  .page2_bg .content {
    margin-top: 30px;
  }
}

.page2_bg .content ul {
  width: 100%;
  background: url(./images/index-industry.png) no-repeat;
  background-size: cover;
  height: 63.66vh;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 50px;
  transform: translateY(200px);
  transition: all .5s;
  transition-delay: .5s;
  opacity: 0;
}

@media (max-width: 767px) {
  .page2_bg .content ul {
    background: none;
  }
}

@media (max-width: 767px) {
  .page2_bg .content ul {
    height: auto;
  }
}

@media (max-width: 1600px) {
  .page2_bg .content ul {
    margin-top: 5.5vh;
  }
}

@media (max-width: 767px) {
  .page2_bg .content ul {
    margin-top: 30px;
  }
}

@media (max-width: 1024px) {
  .page2_bg .content ul {
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .page2_bg .content ul {
    opacity: 1;
  }
}

.page2_bg .content ul li {
  width: calc(100% / 5);
  height: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .page2_bg .content ul li {
    width: 49%;
  }
}

@media (max-width: 767px) {
  .page2_bg .content ul li {
    margin-bottom: 10px;
  }
}

.page2_bg .content ul li .model {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.page2_bg .content ul li .model img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  transform: scale(1.1);
}

.page2_bg .content ul li .pic {
  position: absolute;
  top: -100%;
  left: 0;
  transition: all .5s;
  width: 100%;
  height: 72.5%;
}

@media (max-width: 767px) {
  .page2_bg .content ul li .pic {
    position: relative;
  }
}

@media (max-width: 767px) {
  .page2_bg .content ul li .pic {
    top: 0;
  }
}

.page2_bg .content ul li .pic img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: none;
}

@media (max-width: 767px) {
  .page2_bg .content ul li .pic img {
    aspect-ratio: 0.64789;
  }
}

.page2_bg .content ul li .bottom {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  height: 27.5%;
  padding-top: 40px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 47px;
  transition: all .3s;
  background: none;
}

@media (max-width: 767px) {
  .page2_bg .content ul li .bottom {
    position: relative;
  }
}

@media (max-width: 1600px) {
  .page2_bg .content ul li .bottom {
    padding-top: 20px;
  }
}

@media (max-width: 767px) {
  .page2_bg .content ul li .bottom {
    padding-top: 10px;
  }
}

@media (max-width: 1600px) {
  .page2_bg .content ul li .bottom {
    padding-left: 20px;
  }
}

@media (max-width: 767px) {
  .page2_bg .content ul li .bottom {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .page2_bg .content ul li .bottom {
    padding-right: 20px;
  }
}

@media (max-width: 767px) {
  .page2_bg .content ul li .bottom {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .page2_bg .content ul li .bottom {
    padding-bottom: 25px;
  }
}

@media (max-width: 767px) {
  .page2_bg .content ul li .bottom {
    padding-bottom: 15px;
  }
}

@media (max-width: 767px) {
  .page2_bg .content ul li .bottom {
    background: linear-gradient(180deg, #1e3c72 0%, #2a5298 100%);
  }
}

.page2_bg .content ul li .bottom .number {
  font-family: SourceHanSansCN-Regular;
  font-size: 20px;
  letter-spacing: 2px;
  color: #ffffff;
  margin-bottom: 25px;
}

@media (max-width: 1600px) {
  .page2_bg .content ul li .bottom .number {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .page2_bg .content ul li .bottom .number {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .page2_bg .content ul li .bottom .number {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .page2_bg .content ul li .bottom .number {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .page2_bg .content ul li .bottom .number {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .page2_bg .content ul li .bottom .number {
    letter-spacing: 1.8px;
  }
}

@media (max-width: 1366px) {
  .page2_bg .content ul li .bottom .number {
    letter-spacing: 1.6px;
  }
}

@media (max-width: 1024px) {
  .page2_bg .content ul li .bottom .number {
    letter-spacing: 1.4px;
  }
}

@media (max-width: 991px) {
  .page2_bg .content ul li .bottom .number {
    letter-spacing: 1.2px;
  }
}

@media (max-width: 767px) {
  .page2_bg .content ul li .bottom .number {
    letter-spacing: 1px;
  }
}

@media (max-width: 1600px) {
  .page2_bg .content ul li .bottom .number {
    margin-bottom: 15px;
  }
}

.page2_bg .content ul li .bottom .text {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.page2_bg .content ul li .bottom .text h2 {
  font-family: PangMenZhengDao;
  font-size: 31px;
  color: #eeeeee;
  letter-spacing: 3px;
}

@media (max-width: 1600px) {
  .page2_bg .content ul li .bottom .text h2 {
    font-size: 28px;
  }
}

@media (max-width: 1366px) {
  .page2_bg .content ul li .bottom .text h2 {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .page2_bg .content ul li .bottom .text h2 {
    font-size: 20px;
  }
}

@media (max-width: 1600px) {
  .page2_bg .content ul li .bottom .text h2 {
    letter-spacing: 2.6px;
  }
}

@media (max-width: 1366px) {
  .page2_bg .content ul li .bottom .text h2 {
    letter-spacing: 2.2px;
  }
}

@media (max-width: 1024px) {
  .page2_bg .content ul li .bottom .text h2 {
    letter-spacing: 1.8px;
  }
}

@media (max-width: 991px) {
  .page2_bg .content ul li .bottom .text h2 {
    letter-spacing: 1.4px;
  }
}

@media (max-width: 767px) {
  .page2_bg .content ul li .bottom .text h2 {
    letter-spacing: 1px;
  }
}

.page2_bg .content ul li .color-block {
  background-image: linear-gradient(180deg, #1e3c72 0%, #2a5298 100%);
  position: absolute;
  width: 100%;
  height: 27.5%;
  left: 0;
  bottom: -100%;
  z-index: 1;
  transition: all .5s;
  display: block;
}

@media (max-width: 767px) {
  .page2_bg .content ul li .color-block {
    display: none;
  }
}

.page2_bg .content ul li:nth-child(2n) .model {
  background: rgba(255, 255, 255, 0.1);
}

.page2_bg .content ul li:hover .pic {
  top: 0;
}

.page2_bg .content ul li:hover .color-block {
  bottom: 0;
}

.page3_bg {
  padding-top: 10.65vh;
  background: url(./images/index-solution-bg.png) no-repeat;
  background-size: cover;
}

@media (max-width: 767px) {
  .page3_bg {
    padding-top: 60px;
  }
}

.page3_bg .content {
  width: 85.9%;
  margin: auto;
  margin-top: 6.52vh;
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .page3_bg .content {
    width: 98%;
  }
}

@media (max-width: 767px) {
  .page3_bg .content {
    margin-top: 30px;
  }
}

.page3_bg .content .index-title h2 {
  color: #fff;
}

.page3_bg .content ul {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
}

@media (max-width: 1600px) {
  .page3_bg .content ul {
    margin-top: 28px;
  }
}

@media (max-width: 1366px) {
  .page3_bg .content ul {
    margin-top: 26px;
  }
}

@media (max-width: 1024px) {
  .page3_bg .content ul {
    margin-top: 24px;
  }
}

@media (max-width: 991px) {
  .page3_bg .content ul {
    margin-top: 22px;
  }
}

@media (max-width: 767px) {
  .page3_bg .content ul {
    margin-top: 20px;
  }
}

.page3_bg .content ul li {
  width: 394px;
  aspect-ratio: 394 / 289;
  background: rgba(255, 255, 255, 0.7);
  transition: all .3s;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 1600px) {
  .page3_bg .content ul li {
    width: 24%;
  }
}

@media (max-width: 767px) {
  .page3_bg .content ul li {
    width: 49.5%;
  }
}

@media (max-width: 1600px) {
  .page3_bg .content ul li {
    margin-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .page3_bg .content ul li {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .page3_bg .content ul li {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .page3_bg .content ul li {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .page3_bg .content ul li {
    margin-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .page3_bg .content ul li {
    padding-left: 18px;
  }
}

@media (max-width: 1366px) {
  .page3_bg .content ul li {
    padding-left: 16px;
  }
}

@media (max-width: 1024px) {
  .page3_bg .content ul li {
    padding-left: 14px;
  }
}

@media (max-width: 991px) {
  .page3_bg .content ul li {
    padding-left: 12px;
  }
}

@media (max-width: 767px) {
  .page3_bg .content ul li {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .page3_bg .content ul li {
    padding-right: 18px;
  }
}

@media (max-width: 1366px) {
  .page3_bg .content ul li {
    padding-right: 16px;
  }
}

@media (max-width: 1024px) {
  .page3_bg .content ul li {
    padding-right: 14px;
  }
}

@media (max-width: 991px) {
  .page3_bg .content ul li {
    padding-right: 12px;
  }
}

@media (max-width: 767px) {
  .page3_bg .content ul li {
    padding-right: 10px;
  }
}

.page3_bg .content ul li .pic {
  width: 96px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: solid 1px #595959;
  transition: all .3s;
  position: relative;
  margin: auto;
  margin-top: 4.7vh;
}

@media (max-width: 1600px) {
  .page3_bg .content ul li .pic {
    width: 88.8px;
  }
}

@media (max-width: 1366px) {
  .page3_bg .content ul li .pic {
    width: 81.6px;
  }
}

@media (max-width: 1024px) {
  .page3_bg .content ul li .pic {
    width: 74.4px;
  }
}

@media (max-width: 991px) {
  .page3_bg .content ul li .pic {
    width: 67.2px;
  }
}

@media (max-width: 767px) {
  .page3_bg .content ul li .pic {
    width: 60px;
  }
}

@media (max-width: 1600px) {
  .page3_bg .content ul li .pic {
    height: 88.8px;
  }
}

@media (max-width: 1366px) {
  .page3_bg .content ul li .pic {
    height: 81.6px;
  }
}

@media (max-width: 1024px) {
  .page3_bg .content ul li .pic {
    height: 74.4px;
  }
}

@media (max-width: 991px) {
  .page3_bg .content ul li .pic {
    height: 67.2px;
  }
}

@media (max-width: 767px) {
  .page3_bg .content ul li .pic {
    height: 60px;
  }
}

@media (max-width: 767px) {
  .page3_bg .content ul li .pic {
    margin-top: 20px;
  }
}

.page3_bg .content ul li .pic img {
  position: absolute;
  transition: all .3s;
  max-width: fit-content;
  width: 100%;
}

@media (max-width: 1600px) {
  .page3_bg .content ul li .pic img {
    width: 92%;
  }
}

@media (max-width: 1366px) {
  .page3_bg .content ul li .pic img {
    width: 84%;
  }
}

@media (max-width: 1024px) {
  .page3_bg .content ul li .pic img {
    width: 76%;
  }
}

@media (max-width: 991px) {
  .page3_bg .content ul li .pic img {
    width: 68%;
  }
}

@media (max-width: 767px) {
  .page3_bg .content ul li .pic img {
    width: 60%;
  }
}

.page3_bg .content ul li .pic img.img1 {
  opacity: 1;
}

.page3_bg .content ul li .pic img.img2 {
  opacity: 0;
}

.page3_bg .content ul li h2 {
  text-align: center;
  font-family: SourceHanSansCN-Regular;
  font-size: 24px;
  letter-spacing: 1px;
  color: #595959;
  transition: all .3s;
  margin-top: 3.45vh;
  margin-bottom: 2.7vh;
}

@media (max-width: 1600px) {
  .page3_bg .content ul li h2 {
    font-size: 23.04px;
  }
}

@media (max-width: 1366px) {
  .page3_bg .content ul li h2 {
    font-size: 22.08px;
  }
}

@media (max-width: 1024px) {
  .page3_bg .content ul li h2 {
    font-size: 21.12px;
  }
}

@media (max-width: 991px) {
  .page3_bg .content ul li h2 {
    font-size: 20.16px;
  }
}

@media (max-width: 767px) {
  .page3_bg .content ul li h2 {
    font-size: 19.2px;
  }
}

@media (max-width: 767px) {
  .page3_bg .content ul li h2 {
    margin-top: 15px;
  }
}

@media (max-width: 767px) {
  .page3_bg .content ul li h2 {
    margin-bottom: 10px;
  }
}

.page3_bg .content ul li .more {
  opacity: 0;
  transition: all .3s;
}

.page3_bg .content ul li .more img {
  margin: auto;
}

.page3_bg .content ul li:hover {
  background: #fff;
}

.page3_bg .content ul li:hover .pic {
  border-color: #022A9D;
}

.page3_bg .content ul li:hover .pic img.img1 {
  opacity: 0;
}

.page3_bg .content ul li:hover .pic img.img2 {
  opacity: 1;
}

.page3_bg .content ul li:hover h2 {
  color: #022A9D;
}

.page3_bg .content ul li:hover .more {
  opacity: 1;
}

.page3_bg .content ul .placeholder {
  width: 394px;
  aspect-ratio: 394 / 289;
  transition: all .3s;
  margin-bottom: 20px;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width: 1600px) {
  .page3_bg .content ul .placeholder {
    width: 24%;
  }
}

@media (max-width: 767px) {
  .page3_bg .content ul .placeholder {
    width: 49.5%;
  }
}

@media (max-width: 1600px) {
  .page3_bg .content ul .placeholder {
    margin-bottom: 18px;
  }
}

@media (max-width: 1366px) {
  .page3_bg .content ul .placeholder {
    margin-bottom: 16px;
  }
}

@media (max-width: 1024px) {
  .page3_bg .content ul .placeholder {
    margin-bottom: 14px;
  }
}

@media (max-width: 991px) {
  .page3_bg .content ul .placeholder {
    margin-bottom: 12px;
  }
}

@media (max-width: 767px) {
  .page3_bg .content ul .placeholder {
    margin-bottom: 10px;
  }
}

@media (max-width: 1600px) {
  .page3_bg .content ul .placeholder {
    padding-left: 18px;
  }
}

@media (max-width: 1366px) {
  .page3_bg .content ul .placeholder {
    padding-left: 16px;
  }
}

@media (max-width: 1024px) {
  .page3_bg .content ul .placeholder {
    padding-left: 14px;
  }
}

@media (max-width: 991px) {
  .page3_bg .content ul .placeholder {
    padding-left: 12px;
  }
}

@media (max-width: 767px) {
  .page3_bg .content ul .placeholder {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .page3_bg .content ul .placeholder {
    padding-right: 18px;
  }
}

@media (max-width: 1366px) {
  .page3_bg .content ul .placeholder {
    padding-right: 16px;
  }
}

@media (max-width: 1024px) {
  .page3_bg .content ul .placeholder {
    padding-right: 14px;
  }
}

@media (max-width: 991px) {
  .page3_bg .content ul .placeholder {
    padding-right: 12px;
  }
}

@media (max-width: 767px) {
  .page3_bg .content ul .placeholder {
    padding-right: 10px;
  }
}

.page4_bg {
  background: url(./images/index-video.jpg) no-repeat;
  background-size: cover;
  opacity: 0;
  transition: all .5s;
  transition-delay: .5s;
  position: relative;
  margin-top: 0;
}

@media (max-width: 1024px) {
  .page4_bg {
    opacity: 1;
  }
}

@media (max-width: 1024px) {
  .page4_bg {
    margin-top: 30px;
  }
}

.page4_bg .content {
  width: 1560px;
  margin: auto;
  padding-top: 16.3vh;
  margin-top: 10.65vh;
  padding-bottom: 0;
}

@media (max-width: 1600px) {
  .page4_bg .content {
    width: 90%;
  }
}

@media (max-width: 767px) {
  .page4_bg .content {
    width: 98%;
  }
}

@media (max-width: 1024px) {
  .page4_bg .content {
    padding-top: 30px;
  }
}

@media (max-width: 1024px) {
  .page4_bg .content {
    margin-top: 0;
  }
}

@media (max-width: 1024px) {
  .page4_bg .content {
    padding-bottom: 30px;
  }
}

.page4_bg .content .text {
  color: #fff;
  text-align: center;
  transform: translateY(-200px);
  transition: all .5s;
  transition-delay: .5s;
  opacity: 0;
}

@media (max-width: 1024px) {
  .page4_bg .content .text {
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .page4_bg .content .text {
    opacity: 1;
  }
}

.page4_bg .content .text h2 {
  font-family: PangMenZhengDao;
  font-size: 40px;
  letter-spacing: 4px;
  margin-bottom: 3.8vh;
}

@media (max-width: 1600px) {
  .page4_bg .content .text h2 {
    font-size: 36.8px;
  }
}

@media (max-width: 1366px) {
  .page4_bg .content .text h2 {
    font-size: 33.6px;
  }
}

@media (max-width: 1024px) {
  .page4_bg .content .text h2 {
    font-size: 30.4px;
  }
}

@media (max-width: 991px) {
  .page4_bg .content .text h2 {
    font-size: 27.2px;
  }
}

@media (max-width: 767px) {
  .page4_bg .content .text h2 {
    font-size: 24px;
  }
}

@media (max-width: 1600px) {
  .page4_bg .content .text h2 {
    letter-spacing: 3.6px;
  }
}

@media (max-width: 1366px) {
  .page4_bg .content .text h2 {
    letter-spacing: 3.2px;
  }
}

@media (max-width: 1024px) {
  .page4_bg .content .text h2 {
    letter-spacing: 2.8px;
  }
}

@media (max-width: 991px) {
  .page4_bg .content .text h2 {
    letter-spacing: 2.4px;
  }
}

@media (max-width: 767px) {
  .page4_bg .content .text h2 {
    letter-spacing: 2px;
  }
}

@media (max-width: 767px) {
  .page4_bg .content .text h2 {
    margin-bottom: 20px;
  }
}

.page4_bg .content .text p {
  font-family: SourceHanSansCN-Light;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 35px;
}

@media (max-width: 1600px) {
  .page4_bg .content .text p {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .page4_bg .content .text p {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .page4_bg .content .text p {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .page4_bg .content .text p {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .page4_bg .content .text p {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .page4_bg .content .text p {
    line-height: 34px;
  }
}

@media (max-width: 1366px) {
  .page4_bg .content .text p {
    line-height: 33px;
  }
}

@media (max-width: 1024px) {
  .page4_bg .content .text p {
    line-height: 32px;
  }
}

@media (max-width: 991px) {
  .page4_bg .content .text p {
    line-height: 31px;
  }
}

@media (max-width: 767px) {
  .page4_bg .content .text p {
    line-height: 30px;
  }
}

.page4_bg .content .play {
  cursor: pointer;
  width: fit-content;
  margin: auto;
  margin-top: 8.7vh;
  display: block;
}

@media (max-width: 767px) {
  .page4_bg .content .play {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .page4_bg .content .play {
    display: none;
  }
}

.page4_bg .content ul {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  text-align: center;
  flex-wrap: nowrap;
  margin-top: 13.47vh;
}

@media (max-width: 767px) {
  .page4_bg .content ul {
    flex-wrap: wrap;
  }
}

@media (max-width: 767px) {
  .page4_bg .content ul {
    margin-top: 30px;
  }
}

.page4_bg .content ul li {
  color: #fff;
  width: fit-content;
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .page4_bg .content ul li {
    width: 49%;
  }
}

@media (max-width: 767px) {
  .page4_bg .content ul li {
    margin-bottom: 10px;
  }
}

.page4_bg .content ul li .mun_top {
  display: flex;
  align-items: center;
  font-family: Impact;
  justify-content: center;
  font-size: 68px;
}

@media (max-width: 1600px) {
  .page4_bg .content ul li .mun_top {
    font-size: 62.4px;
  }
}

@media (max-width: 1366px) {
  .page4_bg .content ul li .mun_top {
    font-size: 56.8px;
  }
}

@media (max-width: 1024px) {
  .page4_bg .content ul li .mun_top {
    font-size: 51.2px;
  }
}

@media (max-width: 991px) {
  .page4_bg .content ul li .mun_top {
    font-size: 45.6px;
  }
}

@media (max-width: 767px) {
  .page4_bg .content ul li .mun_top {
    font-size: 40px;
  }
}

.page4_bg .content ul li p {
  font-family: SourceHanSansCN-Light;
  font-size: 20px;
  letter-spacing: 2px;
}

@media (max-width: 1600px) {
  .page4_bg .content ul li p {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .page4_bg .content ul li p {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .page4_bg .content ul li p {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .page4_bg .content ul li p {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .page4_bg .content ul li p {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .page4_bg .content ul li p {
    letter-spacing: 1.8px;
  }
}

@media (max-width: 1366px) {
  .page4_bg .content ul li p {
    letter-spacing: 1.6px;
  }
}

@media (max-width: 1024px) {
  .page4_bg .content ul li p {
    letter-spacing: 1.4px;
  }
}

@media (max-width: 991px) {
  .page4_bg .content ul li p {
    letter-spacing: 1.2px;
  }
}

@media (max-width: 767px) {
  .page4_bg .content ul li p {
    letter-spacing: 1px;
  }
}

.page4_bg .content ul .line {
  width: 1px;
  background: #fff;
  height: auto;
  opacity: .55;
}

.page4_bg .video {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  display: none;
}

.page4_bg .video video {
  display: block;
  width: 100vh;
  height: 56.2vh;
  object-fit: cover;
}

@media (max-width: 1024px) {
  .page4_bg .video video {
    width: 90%;
  }
}

@media (max-width: 767px) {
  .page4_bg .video video {
    height: auto;
  }
}

.page4_bg .video .out {
  position: fixed;
  top: 5vh;
  right: 5vh;
  cursor: pointer;
}

@media (max-width: 767px) {
  .page4_bg .video .out {
    top: 10px;
  }
}

@media (max-width: 767px) {
  .page4_bg .video .out {
    right: 10px;
  }
}

.page4_bg .video-phone {
  display: none;
  width: 100%;
}

@media (max-width: 767px) {
  .page4_bg .video-phone {
    display: block;
  }
}

.page4_bg .video-phone video {
  object-fit: cover;
  width: 100%;
  height: 30vh;
}

.page5_bg {
  padding-top: 10.65vh;
  background: url(./images/index-news-bg.jpg) no-repeat;
  background-position-y: 10.65vh;
  padding-bottom: 0;
}

@media (max-width: 767px) {
  .page5_bg {
    padding-top: 60px;
  }
}

@media (max-width: 767px) {
  .page5_bg {
    background-position-y: 60px;
  }
}

@media (max-width: 1024px) {
  .page5_bg {
    padding-bottom: 30px;
  }
}

.page5_bg .content {
  padding-left: 135px;
  padding-right: 135px;
  margin-top: 60px;
}

@media (max-width: 1600px) {
  .page5_bg .content {
    padding-left: 7%;
  }
}

@media (max-width: 767px) {
  .page5_bg .content {
    padding-left: 10px;
  }
}

@media (max-width: 1600px) {
  .page5_bg .content {
    padding-right: 7%;
  }
}

@media (max-width: 767px) {
  .page5_bg .content {
    padding-right: 10px;
  }
}

@media (max-width: 1600px) {
  .page5_bg .content {
    margin-top: 54px;
  }
}

@media (max-width: 1366px) {
  .page5_bg .content {
    margin-top: 48px;
  }
}

@media (max-width: 1024px) {
  .page5_bg .content {
    margin-top: 42px;
  }
}

@media (max-width: 991px) {
  .page5_bg .content {
    margin-top: 36px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content {
    margin-top: 30px;
  }
}

.page5_bg .content .top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translateY(-200px);
  transition: all .5s;
  transition-delay: .5s;
  opacity: 0;
  position: relative;
  z-index: 10;
}

@media (max-width: 1024px) {
  .page5_bg .content .top {
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .page5_bg .content .top {
    opacity: 1;
  }
}

.page5_bg .content .top .line {
  width: 100%;
  height: 1px;
  background-color: #707070;
  margin-left: 50px;
  margin-right: 35px;
  flex: 1;
}

@media (max-width: 1600px) {
  .page5_bg .content .top .line {
    margin-left: 45px;
  }
}

@media (max-width: 1366px) {
  .page5_bg .content .top .line {
    margin-left: 40px;
  }
}

@media (max-width: 1024px) {
  .page5_bg .content .top .line {
    margin-left: 35px;
  }
}

@media (max-width: 991px) {
  .page5_bg .content .top .line {
    margin-left: 30px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .top .line {
    margin-left: 25px;
  }
}

@media (max-width: 1600px) {
  .page5_bg .content .top .line {
    margin-right: 31px;
  }
}

@media (max-width: 1366px) {
  .page5_bg .content .top .line {
    margin-right: 27px;
  }
}

@media (max-width: 1024px) {
  .page5_bg .content .top .line {
    margin-right: 23px;
  }
}

@media (max-width: 991px) {
  .page5_bg .content .top .line {
    margin-right: 19px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .top .line {
    margin-right: 15px;
  }
}

.page5_bg .content .top ul {
  display: flex;
  align-items: center;
}

.page5_bg .content .top ul li {
  font-family: OPPOSans-L;
  font-size: 24px;
  letter-spacing: 2px;
  color: #212121;
  display: flex;
  align-items: stretch;
}

@media (max-width: 1600px) {
  .page5_bg .content .top ul li {
    font-size: 23.2px;
  }
}

@media (max-width: 1366px) {
  .page5_bg .content .top ul li {
    font-size: 22.4px;
  }
}

@media (max-width: 1024px) {
  .page5_bg .content .top ul li {
    font-size: 21.6px;
  }
}

@media (max-width: 991px) {
  .page5_bg .content .top ul li {
    font-size: 20.8px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .top ul li {
    font-size: 20px;
  }
}

.page5_bg .content .top ul li a {
  transition: all .3s;
}

.page5_bg .content .top ul li span {
  display: block;
  width: 1px;
  height: auto;
  background-color: #707070;
  margin-left: 35px;
  margin-right: 35px;
}

@media (max-width: 1600px) {
  .page5_bg .content .top ul li span {
    margin-left: 31px;
  }
}

@media (max-width: 1366px) {
  .page5_bg .content .top ul li span {
    margin-left: 27px;
  }
}

@media (max-width: 1024px) {
  .page5_bg .content .top ul li span {
    margin-left: 23px;
  }
}

@media (max-width: 991px) {
  .page5_bg .content .top ul li span {
    margin-left: 19px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .top ul li span {
    margin-left: 15px;
  }
}

@media (max-width: 1600px) {
  .page5_bg .content .top ul li span {
    margin-right: 31px;
  }
}

@media (max-width: 1366px) {
  .page5_bg .content .top ul li span {
    margin-right: 27px;
  }
}

@media (max-width: 1024px) {
  .page5_bg .content .top ul li span {
    margin-right: 23px;
  }
}

@media (max-width: 991px) {
  .page5_bg .content .top ul li span {
    margin-right: 19px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .top ul li span {
    margin-right: 15px;
  }
}

.page5_bg .content .top ul li:last-child span {
  display: none;
}

.page5_bg .content .top ul li:hover, .page5_bg .content .top ul li.active {
  color: #014099;
  font-family: OPPOSans-M;
}

.page5_bg .content .news-content .news {
  display: none;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 11.42vh;
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news {
    margin-top: 30px;
  }
}

.page5_bg .content .news-content .news.active {
  display: flex;
}

.page5_bg .content .news-content .news .left {
  width: 479px;
  box-sizing: border-box;
  padding-top: 72px;
  padding-left: 62px;
  padding-right: 48px;
  padding-bottom: 0;
  background-color: #022a9d;
  position: relative;
  transform: translateX(-200px);
  transition: all .5s;
  transition-delay: .5s;
  opacity: 0;
}

@media (max-width: 1600px) {
  .page5_bg .content .news-content .news .left {
    width: 30%;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news .left {
    width: 100%;
  }
}

@media (max-width: 1600px) {
  .page5_bg .content .news-content .news .left {
    padding-top: 7vh;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news .left {
    padding-top: 20px;
  }
}

@media (max-width: 1600px) {
  .page5_bg .content .news-content .news .left {
    padding-left: 40px;
  }
}

@media (max-width: 1366px) {
  .page5_bg .content .news-content .news .left {
    padding-left: 25px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news .left {
    padding-left: 15px;
  }
}

@media (max-width: 1600px) {
  .page5_bg .content .news-content .news .left {
    padding-right: 35px;
  }
}

@media (max-width: 1366px) {
  .page5_bg .content .news-content .news .left {
    padding-right: 25px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news .left {
    padding-right: 15px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news .left {
    padding-bottom: 20px;
  }
}

@media (max-width: 1024px) {
  .page5_bg .content .news-content .news .left {
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .page5_bg .content .news-content .news .left {
    opacity: 1;
  }
}

.page5_bg .content .news-content .news .left .bg img {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.page5_bg .content .news-content .news .left .text {
  position: relative;
  z-index: 1;
  color: #fff;
}

.page5_bg .content .news-content .news .left .text .time {
  display: flex;
  align-items: center;
  margin-bottom: 5.43vh;
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news .left .text .time {
    margin-bottom: 20px;
  }
}

.page5_bg .content .news-content .news .left .text .time span {
  color: #fff;
  font-size: 24px;
}

.page5_bg .content .news-content .news .left .text .time h2 {
  font-family: OPPOSans-R;
  font-size: 24px;
  margin-left: 25px;
}

@media (max-width: 1600px) {
  .page5_bg .content .news-content .news .left .text .time h2 {
    font-size: 23.04px;
  }
}

@media (max-width: 1366px) {
  .page5_bg .content .news-content .news .left .text .time h2 {
    font-size: 22.08px;
  }
}

@media (max-width: 1024px) {
  .page5_bg .content .news-content .news .left .text .time h2 {
    font-size: 21.12px;
  }
}

@media (max-width: 991px) {
  .page5_bg .content .news-content .news .left .text .time h2 {
    font-size: 20.16px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news .left .text .time h2 {
    font-size: 19.2px;
  }
}

@media (max-width: 1600px) {
  .page5_bg .content .news-content .news .left .text .time h2 {
    margin-left: 23px;
  }
}

@media (max-width: 1366px) {
  .page5_bg .content .news-content .news .left .text .time h2 {
    margin-left: 21px;
  }
}

@media (max-width: 1024px) {
  .page5_bg .content .news-content .news .left .text .time h2 {
    margin-left: 19px;
  }
}

@media (max-width: 991px) {
  .page5_bg .content .news-content .news .left .text .time h2 {
    margin-left: 17px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news .left .text .time h2 {
    margin-left: 15px;
  }
}

.page5_bg .content .news-content .news .left .text p {
  font-family: OPPOSans-R;
  font-size: 16px;
  line-height: 28px;
  margin-bottom: 3.26vh;
  text-align: justify;
}

@media (max-width: 1600px) {
  .page5_bg .content .news-content .news .left .text p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page5_bg .content .news-content .news .left .text p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page5_bg .content .news-content .news .left .text p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page5_bg .content .news-content .news .left .text p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news .left .text p {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news .left .text p {
    margin-bottom: 15px;
  }
}

.page5_bg .content .news-content .news .left .text .more {
  width: 128px;
  height: 28px;
  border-radius: 20px;
  background: #fff;
  display: flex;
  align-items: center;
  padding-left: 5px;
  font-size: 16px;
  color: #022A9D;
  font-family: OPPOSans-R;
}

.page5_bg .content .news-content .news .left .text .more .scroll-cont {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 24px;
}

.page5_bg .content .news-content .news .left .text .more .scroll-cont div {
  display: flex;
  align-items: center;
  transition: all .3s;
}

.page5_bg .content .news-content .news .left .text .more .scroll-cont div span {
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #022A9D;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-right: 15px;
}

.page5_bg .content .news-content .news .left:hover .text .more .scroll-cont div {
  transform: translateY(-100%);
}

.page5_bg .content .news-content .news .left:hover .text .more .scroll-cont div img {
  opacity: 1;
}

.page5_bg .content .news-content .news ul {
  width: 1015px;
  transform: translateX(200px);
  transition: all .5s;
  transition-delay: .5s;
  opacity: 0;
  margin-top: 0;
}

@media (max-width: 1600px) {
  .page5_bg .content .news-content .news ul {
    width: 65%;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news ul {
    width: 100%;
  }
}

@media (max-width: 1024px) {
  .page5_bg .content .news-content .news ul {
    transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .page5_bg .content .news-content .news ul {
    opacity: 1;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news ul {
    margin-top: 20px;
  }
}

.page5_bg .content .news-content .news ul li {
  margin-bottom: 16px;
}

.page5_bg .content .news-content .news ul li:last-child {
  margin-bottom: 0;
}

.page5_bg .content .news-content .news ul li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news ul li a {
    flex-wrap: wrap;
  }
}

.page5_bg .content .news-content .news ul li a .pic {
  width: 457px;
  margin-bottom: 0;
  aspect-ratio: 457 / 219;
  overflow: hidden;
}

@media (max-width: 1600px) {
  .page5_bg .content .news-content .news ul li a .pic {
    width: 45%;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news ul li a .pic {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news ul li a .pic {
    margin-bottom: 20px;
  }
}

.page5_bg .content .news-content .news ul li a .pic img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all .3s;
}

.page5_bg .content .news-content .news ul li a .text {
  width: 503px;
}

@media (max-width: 1600px) {
  .page5_bg .content .news-content .news ul li a .text {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news ul li a .text {
    width: 100%;
  }
}

.page5_bg .content .news-content .news ul li a .text .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page5_bg .content .news-content .news ul li a .text .title .time {
  display: flex;
  align-items: center;
}

.page5_bg .content .news-content .news ul li a .text .title .time span {
  color: #7f7f7f;
  font-size: 24px;
}

.page5_bg .content .news-content .news ul li a .text .title .time h2 {
  font-family: OPPOSans-R;
  font-size: 20px;
  margin-left: 12px;
  color: #7f7f7f;
}

@media (max-width: 1600px) {
  .page5_bg .content .news-content .news ul li a .text .title .time h2 {
    font-size: 19.2px;
  }
}

@media (max-width: 1366px) {
  .page5_bg .content .news-content .news ul li a .text .title .time h2 {
    font-size: 18.4px;
  }
}

@media (max-width: 1024px) {
  .page5_bg .content .news-content .news ul li a .text .title .time h2 {
    font-size: 17.6px;
  }
}

@media (max-width: 991px) {
  .page5_bg .content .news-content .news ul li a .text .title .time h2 {
    font-size: 16.8px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news ul li a .text .title .time h2 {
    font-size: 16px;
  }
}

@media (max-width: 1600px) {
  .page5_bg .content .news-content .news ul li a .text .title .time h2 {
    margin-left: 11.6px;
  }
}

@media (max-width: 1366px) {
  .page5_bg .content .news-content .news ul li a .text .title .time h2 {
    margin-left: 11.2px;
  }
}

@media (max-width: 1024px) {
  .page5_bg .content .news-content .news ul li a .text .title .time h2 {
    margin-left: 10.8px;
  }
}

@media (max-width: 991px) {
  .page5_bg .content .news-content .news ul li a .text .title .time h2 {
    margin-left: 10.4px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news ul li a .text .title .time h2 {
    margin-left: 10px;
  }
}

.page5_bg .content .news-content .news ul li a .text .title .more {
  width: 115px;
  height: 26px;
  border-radius: 20px;
  background: #022A9D;
  padding-left: 5px;
  font-size: 14px;
  color: #fff;
  font-family: OPPOSans-R;
  display: flex;
  align-items: center;
}

.page5_bg .content .news-content .news ul li a .text .title .more .scroll-cont {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 20px;
}

.page5_bg .content .news-content .news ul li a .text .title .more .scroll-cont div {
  display: flex;
  align-items: center;
  transition: all .3s;
}

.page5_bg .content .news-content .news ul li a .text .title .more .scroll-cont div span {
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  align-items: center;
  justify-content: center;
  color: #022A9D;
  margin-right: 10px;
}

.page5_bg .content .news-content .news ul li a .text p {
  font-family: OPPOSans-R;
  font-size: 16px;
  line-height: 28px;
  margin-top: 28px;
  margin-bottom: 3.26vh;
  text-align: justify;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

@media (max-width: 1600px) {
  .page5_bg .content .news-content .news ul li a .text p {
    font-size: 15.6px;
  }
}

@media (max-width: 1366px) {
  .page5_bg .content .news-content .news ul li a .text p {
    font-size: 15.2px;
  }
}

@media (max-width: 1024px) {
  .page5_bg .content .news-content .news ul li a .text p {
    font-size: 14.8px;
  }
}

@media (max-width: 991px) {
  .page5_bg .content .news-content .news ul li a .text p {
    font-size: 14.4px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news ul li a .text p {
    font-size: 14px;
  }
}

@media (max-width: 1600px) {
  .page5_bg .content .news-content .news ul li a .text p {
    margin-top: 24.4px;
  }
}

@media (max-width: 1366px) {
  .page5_bg .content .news-content .news ul li a .text p {
    margin-top: 20.8px;
  }
}

@media (max-width: 1024px) {
  .page5_bg .content .news-content .news ul li a .text p {
    margin-top: 17.2px;
  }
}

@media (max-width: 991px) {
  .page5_bg .content .news-content .news ul li a .text p {
    margin-top: 13.6px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news ul li a .text p {
    margin-top: 10px;
  }
}

@media (max-width: 767px) {
  .page5_bg .content .news-content .news ul li a .text p {
    margin-bottom: 15px;
  }
}

.page5_bg .content .news-content .news ul li:hover a .pic img {
  transform: scale(1.1);
}

.page5_bg .content .news-content .news ul li:hover a .text .more .scroll-cont div {
  transform: translateY(-100%);
}

.page5_bg .content .news-content .news ul li:hover a .text .more .scroll-cont div img {
  opacity: 1;
}

@keyframes title-left {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
